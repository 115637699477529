import * as React from "react";
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import { Routes, Route } from "react-router-dom";
import theme from "./utils/theme";
import { RootContext } from "./contexts";
import AppBar from "./components/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { getOS, randStr } from "./utils/auxiliary";
import { OpenInAppBanner } from "react-open-in-app-banner";
import Delay from "react-delay";

// Pages
import Auth from "./pages/Auth";
import NotFound from "./pages/404";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

// Lazy loading pages
const Home = React.lazy(() => import("./pages/Home"));
const TV = React.lazy(() => import("./pages/TV"));
const Radio = React.lazy(() => import("./pages/Radio"));
const Search = React.lazy(() => import("./pages/Search"));
const Program = React.lazy(() => import("./pages/Program"));
// const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
// const TermsOfUse = React.lazy(() => import("./pages/TermsOfUse"));

const SuspenseContent = () => {
  return <></>;
};

const CustomCircularProgress = (props) => {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme.palette.grey[600],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: theme.palette.primary.light,
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
};

const Preloader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CustomCircularProgress />
    </Box>
  );
};

const SubscriptionModal = () => {
  const { rootState, setRootState } = React.useContext(RootContext);
  let open = rootState.subscriptionModal;

  const handleClose = () => {
    setRootState({
      ...rootState,
      subscriptionModal: false,
    });
  };

  const os = getOS();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
        "& .MuiDialogContent-root": {
          padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
          padding: theme.spacing(1),
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6" component="span">
          Manage your subscription
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom variant="body2">
          You can upgrade your account and manage your subscription on our
          official mobile apps.
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 1,
          }}
          spacing={2}
        >
          {os !== "Android" && (
            <Grid item xs={6}>
              <Box
                component="a"
                href="https://apps.apple.com/tr/app/haiti-streaming-app/id1568543448"
                target="_blank"
              >
                <Box
                  component="img"
                  src="/img/apple-app-store.svg"
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
            </Grid>
          )}
          {os !== "iOS" && (
            <Grid item xs={6}>
              <Box
                component="a"
                href="https://play.google.com/store/apps/details?id=com.haitistreaming.app"
                target="_blank"
              >
                <Box
                  component="img"
                  src="/img/google-play.svg"
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/* Wrapper of all app contents after authentication */
const Wrapper = () => {
  return (
    <Box sx={{}}>
      <AppBar />
      <Container
        maxWidth="lg"
        sx={{
          py: theme.spacing(5),
        }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<SuspenseContent />}>
                <Home />
              </React.Suspense>
            }
          />
          <Route
            path="/search"
            element={
              <React.Suspense fallback={<SuspenseContent />}>
                <Search />
              </React.Suspense>
            }
          />
          <Route
            path="/tv"
            element={
              <React.Suspense fallback={<SuspenseContent />}>
                <TV />
              </React.Suspense>
            }
          />
          <Route
            path="/radio"
            element={
              <React.Suspense fallback={<SuspenseContent />}>
                <Radio />
              </React.Suspense>
            }
          />
          <Route
            path="/tv/:id"
            element={
              <React.Suspense fallback={<SuspenseContent />}>
                <Program type="tv" />
              </React.Suspense>
            }
          />
          <Route
            path="/radio/:id"
            element={
              <React.Suspense fallback={<SuspenseContent />}>
                <Program type="radio" />
              </React.Suspense>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
      <SubscriptionModal />
    </Box>
  );
};

export default function App() {
  const { rootState, appReady } = React.useContext(RootContext);

  return (
    <>
      {appReady ? (
        <>
          <Delay wait={1000}>
            <Box
              sx={{
                "& svg": {
                  color: "black",
                },
                "& .open-in-app-banner-open-button": {
                  color: `${theme.palette.primary.main} !important`,
                  borderColor: `${theme.palette.primary.main} !important`,
                },
              }}
            >
              <OpenInAppBanner
                style={{ zIndex: 99999, color: "black" }}
                appName="Haiti Streaming"
                bannerDescription="Check out our official app!"
                playStoreAppId="com.haitistreaming.app"
                appStoreAppId="1568543448"
                appStoreAppName="haiti-streaming-app"
                appIcon={
                  <img
                    style={{ width: "2.5rem", height: "2.5rem" }}
                    src={"/img/logo.png"}
                    alt="example"
                  />
                }
              />
            </Box>
          </Delay>
          <Routes>
            <Route
              path="/privacy-policy"
              element={
                <React.Suspense fallback={<SuspenseContent />}>
                  <PrivacyPolicy />
                </React.Suspense>
              }
            />
            <Route
              path="/terms-of-use"
              element={
                <React.Suspense fallback={<SuspenseContent />}>
                  <TermsOfUse />
                </React.Suspense>
              }
            />
            <Route path="*" element={rootState.user ? <Wrapper /> : <Auth />} />
          </Routes>
        </>
      ) : (
        <Preloader />
      )}
    </>
  );
}
