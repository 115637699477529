import * as React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function NotFound(props) {
  React.useEffect(() => {
    document.title = "Page not found | Haiti Streaming";
  }, []);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "middle",
        textAlign: "center",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h6">Page not found.</Typography>
        <Typography
          variant="body2"
          sx={{
            opacity: 0.75,
          }}
        >
          This page does not exist or has been moved.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          component="img"
          src="/img/404.svg"
          sx={{
            maxWidth: "250px",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Button variant="contained">Get me back home</Button>
        </Link>
      </Grid>
    </Grid>
  );
}
