import React, { createContext, Component, useState } from "react";
import { supabase } from "./../utils/supabaseClient";
import { shuffleArray } from "./../utils/auxiliary";

export const RootContext = createContext();

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

class Context extends Component {
  componentDidMount() {
    this.load();
  }

  state = {
    tv: [],
    radio: [],
    user: null,
    loaded: {
      tv: false,
      radio: false,
      user: false,
    },
    search: {
      input: "",
      category: "All",
      keyPrefix: 0,
    },
    subscriptionModal: false,
  };

  logout = () => {
    localStorage.removeItem("uid");
    this.setState({
      ...this.state,
      user: null,
      loaded: {
        ...this.state.loaded,
        user: false,
      },
    });
    this.loadUser();
  };

  setRootState = (newState) => {
    this.setState(newState);
  };

  setRootSearch = (newSearch) => {
    this.setState({
      ...this.state,
      search: newSearch,
    });
  };

  load = async () => {
    let newState = this.state;
    // Load TV channels
    try {
      await supabase
        .from("tv_web")
        .select(`*`)
        .eq("hide", false)
        .then((res) => {
          newState.tv = res.data;
          newState.loaded.tv = true;
        });
    } catch (err) {
      this.state.criticalError =
        "Something went wrong while loading TV channels. Please try again later.";
    }
    // Load Radio channels
    try {
      await supabase
        .from("radio_web")
        .select(`*`)
        .eq("hide", false)
        .then((res) => {
          newState.radio = res.data;
          newState.loaded.radio = true;
        });
    } catch (err) {
      this.state.criticalError =
        "Something went wrong while loading Radio channels. Please try again later.";
    }
    this.setState(newState);
    this.loadUser();
  };

  loadUser = async () => {
    let newState = this.state;
    this.setState({
      ...this.state,
      loaded: {
        ...this.state.loaded,
        user: false,
      },
    });
    // Check user data
    let uid = localStorage.getItem("uid");
    newState.user = null;
    try {
      await supabase
        .from("users")
        .select(`*`)
        .eq("uid", uid)
        .single()
        .then((res) => {
          newState.user = res.data;
          newState.loaded.user = true;
        });
    } catch (err) {
      this.state.criticalError =
        "Something went wrong while loading account data. Please try again later.";
    }
    newState.loaded.user = true;
    this.setState(newState);
  };

  // Get a slideshow of images for the authentication page background
  getSlideshow = () => {
    let images = [];
    for (let i = 0; i < this.state.tv.length; i++) {
      if (this.state.tv[i].logo) {
        images.push(this.state.tv[i].logo);
      }
    }
    for (let i = 0; i < this.state.radio.length; i++) {
      if (this.state.radio[i].logo) {
        images.push(this.state.radio[i].logo);
      }
    }
    return shuffleArray(images);
  };

  getCategories = () => {
    let categories = [];
    for (let i = 0; i < this.state.tv.length; i++) {
      if (
        this.state.tv[i].genre &&
        categories.indexOf(this.state.tv[i].genre) === -1
      ) {
        categories.push(this.state.tv[i].genre);
      }
    }
    for (let i = 0; i < this.state.radio.length; i++) {
      if (
        this.state.radio[i].genre &&
        categories.indexOf(this.state.radio[i].genre) === -1
      ) {
        categories.push(this.state.radio[i].genre);
      }
    }
    return categories;
  };

  render() {
    const contextValue = {
      rootState: this.state,
      setRootState: this.setRootState,
      rootSearch: this.state.search,
      setRootSearch: this.setRootSearch,
      logout: this.logout,
      appReady:
        this.state.loaded.tv &&
        this.state.loaded.radio &&
        this.state.loaded.user,
      getSlideshow: this.getSlideshow,
      loadUser: this.loadUser,
      getCategories: this.getCategories,
    };
    return (
      <RootContext.Provider value={contextValue}>
        {this.props.children}
      </RootContext.Provider>
    );
  }
}

export default Context;
