import * as React from "react";
import {
  Paper,
  Typography,
  Container,
  Grid,
  Box,
  TextField,
  Divider,
  Button,
  Snackbar,
  Alert,
  IconButton,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import theme from "./../utils/theme";
import { randInt } from "./../utils/auxiliary";
import { keyframes } from "@mui/system";
import {
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  signInWithGoogle,
  authErrors,
} from "./../utils/firebase";
import GoogleIcon from "@mui/icons-material/Google";
import CloseIcon from "@mui/icons-material/Close";
import { RootContext } from "./../contexts";
import { supabase } from "./../utils/supabaseClient";

const infiniteScroll = keyframes`
  0% {
    transform: translateY(0)
  }
  50% {
    transform: translateY(calc(-100% + 100vh - ${theme.spacing(3)}))
  }
  100% {
    transform: translateY(0)
  }
`;

const infiniteScrollReverse = keyframes`
  0% {
    transform: translateY(calc(-100% + 100vh - ${theme.spacing(3)}))
  }
  50% {
    transform: translateY(0)
  }
  100% {
    transform: translateY(calc(-100% + 100vh - ${theme.spacing(3)}))
  }
`;

const fadeToHalf = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 0.33
  }
`;

const BackgroundImage = (props) => {
  return (
    <Box
      component="img"
      src={props.src}
      sx={{
        width: `calc(100% - ${theme.spacing(3)})`,
        height: "auto",
        borderRadius: "5px",
        m: theme.spacing(3, 1.5, 0, 1.5),
        transitionDuration: "0.2s",
        cursor: "pointer",
        opacity: 0,
        "&:hover": {
          // opacity: 0.9,
        },
        animation: `${fadeToHalf} 0.25s linear forwards`,
        animationDelay: `0.${randInt(100, 1500)}s`,
      }}
    />
  );
};

const Background = ({ slideshow }) => {
  slideshow.length = 60;
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        overflow: "hidden",
        background: theme.palette.grey[800],
        px: theme.spacing(1.5),
      }}
    >
      <Grid container sx={{}}>
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          className="c"
          sx={{
            animation: `${infiniteScroll} ${randInt(
              150,
              200
            )}s infinite linear`,
          }}
        >
          {slideshow.map((item, index) => {
            return index % 6 === 0 ? (
              <BackgroundImage key={`col-1-${index}`} src={item} />
            ) : (
              <></>
            );
          })}
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          className="c"
          sx={{
            animation: `${infiniteScrollReverse} ${randInt(
              150,
              200
            )}s infinite linear`,
          }}
        >
          {slideshow.map((item, index) => {
            return index % 6 === 1 ? (
              <BackgroundImage key={`col-2-${index}`} src={item} />
            ) : (
              <></>
            );
          })}
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
            animation: `${infiniteScroll} ${randInt(
              150,
              200
            )}s infinite linear`,
          }}
          className="c"
        >
          {slideshow.map((item, index) => {
            return index % 6 === 2 ? (
              <BackgroundImage key={`col-3-${index}`} src={item} />
            ) : (
              <></>
            );
          })}
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          sx={{
            display: {
              xs: "none",
              md: "block",
            },
            animation: `${infiniteScrollReverse} ${randInt(
              150,
              200
            )}s infinite linear`,
          }}
          className="c"
        >
          {slideshow.map((item, index) => {
            return index % 6 === 3 ? (
              <BackgroundImage key={`col-4-${index}`} src={item} />
            ) : (
              <></>
            );
          })}
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          sx={{
            display: {
              xs: "none",
              lg: "block",
            },
            animation: `${infiniteScroll} ${randInt(
              150,
              200
            )}s infinite linear`,
          }}
          className="c"
        >
          {slideshow.map((item, index) => {
            return index % 6 === 4 ? (
              <BackgroundImage key={`col-5-${index}`} src={item} />
            ) : (
              <></>
            );
          })}
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          sx={{
            display: {
              xs: "none",
              lg: "block",
            },
            animation: `${infiniteScrollReverse} ${randInt(
              150,
              200
            )}s infinite linear`,
          }}
          className="c"
        >
          {slideshow.map((item, index) => {
            return index % 6 === 5 ? (
              <BackgroundImage key={`col-6-${index}`} src={item} />
            ) : (
              <></>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

const LoginForm = (props) => {
  const { loadUser } = React.useContext(RootContext);

  const [state, setState] = React.useState({
    input: {
      email: "",
      password: "",
    },
    loading: false,
    error: "",
    errorShown: false,
  });

  const handleInput = (evt) => {
    setState({
      ...state,
      input: {
        ...state.input,
        [evt.target.name]: evt.target.value,
      },
    });
  };

  const submit = async () => {
    if (state.input.email === "" || state.input.password === "") {
      setState({
        ...state,
        errorShown: true,
        error: "Please enter your email address and password.",
      });
      return;
    }
    setState({
      ...state,
      loading: true,
      errorShown: false,
    });
    await logInWithEmailAndPassword(state.input.email, state.input.password)
      .then((res) => {
        let uid = res.user.uid;
        localStorage.setItem("uid", uid);
        loadUser();
      })
      .catch((err) => {
        let errorCode = err.code.split("/")[1];
        setState({
          ...state,
          loading: false,
          error: authErrors[errorCode]
            ? authErrors[errorCode]
            : `Unexpected error: ${errorCode}`,
          errorShown: true,
        });
      });
  };

  const errorClose = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setState({ ...state, errorShown: false })}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        open={state.errorShown}
        autoHideDuration={5000}
        action={errorClose}
        onClose={() => setState({ ...state, errorShown: false })}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert severity="error" sx={{ width: "100%" }} action={errorClose}>
          {state.error}
        </Alert>
      </Snackbar>
      <Box>
        <TextField
          name="email"
          type="email"
          label="Email address"
          placeholder="john@example.com"
          size="small"
          fullWidth
          sx={{ mb: theme.spacing(2) }}
          value={state.input.email}
          onChange={handleInput}
          disabled={state.loading}
        />
        <TextField
          name="password"
          type="password"
          label="Password"
          placeholder="Your account's password"
          size="small"
          fullWidth
          sx={{ mb: theme.spacing(2) }}
          value={state.input.password}
          onChange={handleInput}
          disabled={state.loading}
        />

        <Button
          variant="contained"
          disableElevation
          color="primary"
          fullWidth
          onClick={submit}
          disabled={state.loading}
        >
          {state.loading ? "Loading..." : "Authenticate"}
        </Button>
        <Typography variant="body2" sx={{ mt: theme.spacing(2) }}>
          No account yet?{" "}
          <Typography
            color="primary"
            variant="body2"
            component="span"
            sx={{
              cursor: "pointer",
              color: theme.palette.primary[500],
            }}
            onClick={() => props.setPage("register")}
          >
            Register now
          </Typography>
        </Typography>
      </Box>
    </>
  );
};

const GoogleForm = (props) => {
  const { loadUser } = React.useContext(RootContext);

  const [error, setError] = React.useState({
    shown: false,
    message: "",
  });

  const loginWithGoogle = async () => {
    setError({
      shown: false,
      message: "",
    });
    await signInWithGoogle()
      .then((res) => {
        // Check if user exists
        let uid = res.user.uid;
        let email = res.user.email;
        let { data, error, status } = supabase
          .from("users")
          .select(`*`)
          .eq("uid", uid)
          .single()
          .then((res) => {
            if (res.status === 406) {
              // new user
              const { data, error } = supabase
                .from("users")
                .insert([{ uid: uid, email: email }])
                .then((res) => {
                  localStorage.setItem("uid", uid);
                  loadUser();
                });
            } else {
              localStorage.setItem("uid", uid);
              loadUser();
            }
          });
      })
      .catch((err) => {
        let errorCode = err.code.split("/")[1];
        setError({
          shown: true,
          message: authErrors[errorCode]
            ? authErrors[errorCode]
            : `Unexpected error: ${errorCode}`,
        });
      });
  };

  const errorClose = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setError({ ...error, shown: false })}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Button
        variant="outlined"
        disableElevation
        color="error"
        fullWidth
        startIcon={<GoogleIcon />}
        onClick={loginWithGoogle}
      >
        Continue with Google
      </Button>
      <Snackbar
        open={error.shown}
        autoHideDuration={5000}
        action={errorClose}
        onClose={() => setError({ ...state, shown: false })}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert severity="error" sx={{ width: "100%" }} action={errorClose}>
          {error.message}
        </Alert>
      </Snackbar>
    </>
  );
};

const RegisterForm = (props) => {
  const { rootState, setRootState, loadUser } = React.useContext(RootContext);
  const [state, setState] = React.useState({
    input: {
      email: "",
      password: "",
      agreement: false,
    },
    loading: false,
    error: "",
    errorShown: false,
  });

  const handleInput = (evt) => {
    setState({
      ...state,
      input: {
        ...state.input,
        [evt.target.name]: evt.target.value,
      },
    });
  };

  const handleAgreement = (evt) => {
    setState({
      ...state,
      input: {
        ...state.input,
        agreement: evt.target.checked,
      },
    });
  };

  const submit = async () => {
    if (!state.input.agreement) {
      setState({
        ...state,
        errorShown: true,
        error:
          "You must agree to our Terms of Use and Privacy Policy before proceeding.",
      });
      return;
    } else if (state.input.email === "" || state.input.password === "") {
      setState({
        ...state,
        errorShown: true,
        error: "Please enter your email address and a new password.",
      });
      return;
    } else if (state.input.password.length < 6) {
      setState({
        ...state,
        errorShown: true,
        error:
          "Please choose a password with a length of at least 6 characters.",
      });
      return;
    }
    setState({
      ...state,
      loading: true,
      errorShown: false,
    });
    await registerWithEmailAndPassword(state.input.email, state.input.password)
      .then((res) => {
        let uid = res.user.uid;
        let email = res.user.email;
        localStorage.setItem("uid", res.user.uid);
        const { data, error } = supabase
          .from("users")
          .insert([{ uid: uid, email: email }])
          .then((res) => {
            localStorage.setItem("uid", uid);
            loadUser();
          });
      })
      .catch((err) => {
        let errorCode = err.code.split("/")[1];
        setState({
          ...state,
          loading: false,
          error: authErrors[errorCode]
            ? authErrors[errorCode]
            : `Unexpected error: ${errorCode}`,
          errorShown: true,
        });
      });
  };

  const errorClose = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setState({ ...state, errorShown: false })}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        open={state.errorShown}
        autoHideDuration={5000}
        action={errorClose}
        onClose={() => setState({ ...state, errorShown: false })}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert severity="error" sx={{ width: "100%" }} action={errorClose}>
          {state.error}
        </Alert>
      </Snackbar>
      <Box>
        <TextField
          name="email"
          type="email"
          label="Email address"
          placeholder="john@example.com"
          size="small"
          fullWidth
          sx={{ mb: theme.spacing(2) }}
          value={state.input.email}
          onChange={handleInput}
          disabled={state.loading}
        />
        <TextField
          name="password"
          type="password"
          label="Password"
          placeholder="Choose a new password"
          size="small"
          fullWidth
          sx={{ mb: theme.spacing(2) }}
          value={state.input.password}
          onChange={handleInput}
          disabled={state.loading}
        />
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              onChange={handleAgreement}
              checked={state.input.agreement}
            />
          }
          sx={{
            mb: theme.spacing(2),
          }}
          label={
            <Typography
              variant="body2"
              sx={{
                lineHeight: 1.2,
                "& a": {
                  // textDecoration: "none",
                  color: theme.palette.primary[500],
                },
              }}
            >
              I agree to the{" "}
              <a href="/terms-of-use" target="_blank">
                Terms of Use
              </a>{" "}
              and the{" "}
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
              .
            </Typography>
          }
          labelPlacement="end"
        />
        <Button
          variant="contained"
          disableElevation
          color="primary"
          fullWidth
          onClick={submit}
          disabled={state.loading}
        >
          {state.loading ? "Loading..." : "Register"}
        </Button>
        <Typography variant="body2" sx={{ mt: theme.spacing(2) }}>
          Already signed up?{" "}
          <Typography
            color="primary"
            variant="body2"
            component="span"
            sx={{
              cursor: "pointer",
              color: theme.palette.primary[500],
            }}
            onClick={() => props.setPage("login")}
          >
            Log in instead
          </Typography>
        </Typography>
      </Box>
    </>
  );
};

const PasswordForm = (props) => {
  return <>Reset your password here</>;
};

const Form = () => {
  const [page, setPage] = React.useState("login");

  return (
    <Paper
      sx={{
        p: theme.spacing(3),
        background: "rgba(0,0,0,0.85)",
        backdropFilter: "saturate(500%) blur(5px)",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          component="h2"
          sx={{
            mb: theme.spacing(0),
          }}
        >
          {page == "login" && <>Welcome back!</>}
          {page == "register" && <>Welcome!</>}
          {page == "password" && <>Reset your password.</>}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mb: theme.spacing(3),
          }}
        >
          {page == "login" && (
            <>Log into your account to access Haiti Streaming.</>
          )}
          {page == "register" && <>Create a new Haiti Streaming account.</>}
          {page == "password" && <>Reset your Haiti Streaming password.</>}
        </Typography>
      </Box>
      {page == "login" && <LoginForm setPage={setPage} />}
      {page == "register" && <RegisterForm setPage={setPage} />}
      {page == "password" && <PasswordForm setPage={setPage} />}
      <Box>
        <Divider
          sx={{
            m: theme.spacing(2, 0),
            "&.MuiDivider-root": {
              "&:after": {
                borderColor: "rgba(255,255,255,.25)",
              },
              "&:before": {
                borderColor: "rgba(255,255,255,.25)",
              },
            },
          }}
        >
          or
        </Divider>
        <GoogleForm />
      </Box>
    </Paper>
  );
};

export default function Auth(props) {
  const { getSlideshow } = React.useContext(RootContext);

  React.useEffect(() => {
    document.title = "Log in or register | Haiti Streaming";
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Background slideshow={getSlideshow()} />
        <Container
          maxWidth="sm"
          sx={{
            position: "relative",
            zIndex: 9,
            py: theme.spacing(3),
          }}
        >
          <Form />
        </Container>
      </Box>
    </>
  );
}
