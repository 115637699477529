import * as React from "react";
import { Container, Box, Grid, Typography, Paper } from "@mui/material";
import AppBar from "./../components/Menu";
import theme from "./../utils/theme";

export default function TermsOfUse(props) {
  React.useEffect(() => {
    document.title = "Terms of Use | Haiti Streaming";
  }, []);

  return (
    <Box sx={{}}>
      <AppBar />
      <Container
        maxWidth="lg"
        sx={{
          py: theme.spacing(5),
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h1"
              align="center"
              sx={{
                mb: 3,
                color: "white",
              }}
            >
              Terms of Use
            </Typography>
            <Paper
              sx={{
                p: theme.spacing(2, 3),
                background: theme.palette.custom.blackDark,
              }}
            >
              <Typography>
                1. BINDING EFFECT. <br />
                <br />
                This is a binding agreement between you and Haiti Streaming
                (”us”, ”we”, ”Company”). By using the Internet site located at
                https://haitistreaming.com (the ”Site”), you agree to abide by
                these Terms of Use. If at any time you find these Terms of Use
                unacceptable, you must immediately leave the Site and cease all
                use of it. <br />
                <br />
                2. PRIVACY POLICY. <br />
                <br />
                We respect your privacy and permit you to control the treatment
                of your personal information. A complete statement of our
                current privacy policy can be found by clicking here. Our
                privacy policy is expressly incorporated into this Agreement by
                this reference. <br />
                <br />
                3. GOVERNING LAW. <br />
                <br />
                These Terms of Use shall be construed in accordance with and
                governed by the laws of California and the United States,
                without reference to rules regarding conflicts of law. This Site
                is intended for use by individuals based in the United States of
                America. <br />
                <br />
                4. MINIMUM <br />
                <br />
                AGE. You must be at least 18 years old to access and participate
                on this site. You guarantee and warrant you are at least 18
                years old and are able to enter into this Agreement from a legal
                perspective. <br />
                <br />
                5. EBOOK SIGNUPS AND MAILINGS. <br />
                <br />
                You have the option, but not obligation, to sign up and receive
                a free eBook from us. Should you do so, you are agreeing to
                receive further emailings from us of a commercial nature. <br />
                <br />
                6. EMAIL COMMUNICATIONS. <br />
                <br />
                When you contact us, you expressly consent and agree to receive
                email responses from us. These email communications may be
                commercial or non-commercial in nature. Non-commercial emails
                may include, but are not limited to, administrative issues and
                announcements of changes to these Terms, the Privacy Policy or
                other site documentation. <br />
                <br />
                7. USE OF SOFTWARE. <br />
                <br />
                Company may make certain software available to you from the
                Site. If you download software from the Site, the software,
                including all files and images contained in or generated by the
                software, and accompanying data (collectively, ”Software”) are
                deemed to be licensed to you by Company, for your personal,
                noncommercial, home use only. Company does not transfer either
                the title or the intellectual property rights to the Software,
                and Company retains full and complete title to the Software as
                well as all intellectual property rights therein. You may not
                sell, redistribute, or reproduce the Software, nor may you
                decompile, reverse-engineer, disassemble, or otherwise convert
                the Software to a human-perceivable form. All trademarks and
                logos are owned by Company or its licensors and you may not copy
                or use them in any manner. <br />
                <br />
                8. USER CONTENT. <br />
                <br />
                By posting, downloading, displaying, performing, transmitting,
                or otherwise distributing information or other content (”User
                Content”) to the site, you are granting Company, its affiliates,
                officers, directors, employees, consultants, agents, and
                representatives a permanent, non-exclusive license to use User
                Content in connection with the operation of the Internet
                businesses of Company, its affiliates, officers, directors,
                employees, consultants, agents, and representatives, including
                without limitation, a right to copy, distribute, transmit,
                publicly display, publicly perform, reproduce, edit, translate,
                and reformat User Content. You will not be compensated for any
                User Content. You agree that Company may publish or otherwise
                disclose your name in connection with your User Content. By
                posting User Content on the site, you warrant and represent that
                you own the rights to the User Content or are otherwise
                authorized to post, distribute, display, perform, transmit, or
                otherwise distribute User Content. <br />
                <br />
                9. COMPLIANCE WITH INTELLECTUAL PROPERTY LAWS. <br />
                <br />
                When accessing the site, you agree to respect the intellectual
                property rights of others. Your use of the site is at all times
                governed by and subject to laws regarding copyright ownership
                and use of intellectual property. You agree not to upload,
                download, display, perform, transmit, or otherwise distribute
                any information or content (collectively, ”Content”) in
                violation of any third party's copyrights, trademarks, or other
                intellectual property or proprietary rights. You agree to abide
                by laws regarding copyright ownership and use of intellectual
                property, and you shall be solely responsible for any violations
                of any relevant laws and for any infringements of third party
                rights caused by any Content you provide or transmit, or that is
                provided or transmitted using your User ID. The burden of
                proving that any Content does not violate any laws or third
                party rights rests solely with you. All Digital Millennium
                Copyright Act matters are processed pursuant to our DMCA Policy,
                which you may access via the DMCA link at the bottom of the
                page. <br />
                <br />
                10. INAPPROPRIATE CONTENT. <br />
                <br />
                You agree not to upload, download, display, perform, transmit,
                or otherwise distribute any Content that (a) is libelous,
                defamatory, obscene, pornographic, abusive, or threatening; (b)
                advocates or encourages conduct that could constitute a criminal
                offense, give rise to civil liability, or otherwise violate any
                applicable local, state, national, or foreign law or regulation;
                (c) advertises or otherwise solicits funds or is a solicitation
                for goods or services; or (d) provides medical advice to other
                users. Company reserves the right to terminate your receipt,
                transmission, or other distribution of any such material using
                the site, and, if applicable, to delete any such material from
                its servers. Company intends to cooperate fully with any law
                enforcement officials or agencies in the investigation of any
                violation of these Terms or of any applicable laws. <br />
                <br />
                11. COMPLIANCE WITH INTELLECTUAL PROPERTY LAWS. <br />
                <br />
                When accessing the Site, you agree to obey the law and to
                respect the intellectual property rights of others. Your use of
                the Site is at all times governed by and subject to laws
                regarding copyright ownership and use of intellectual property.
                You agree not to upload, download, display, perform, transmit,
                or otherwise distribute any information or content
                (collectively, ”Content”) in violation of any third party's
                copyrights, trademarks, or other intellectual property or
                proprietary rights. You agree to abide by laws regarding
                copyright ownership and use of intellectual property, and you
                shall be solely responsible for any violations of any relevant
                laws and for any infringements of third party rights caused by
                any Content you provide or transmit, or that is provided or
                transmitted using your account. The burden of proving that any
                Content does not violate any laws or third party rights rests
                solely with you. <br />
                <br />
                12. NO WARRANTIES. <br />
                <br />
                WE HEREBY DISCLAIM ALL WARRANTIES. WE ARE MAKING THE SITE
                AVAILABLE ”AS IS” WITHOUT WARRANTY OF ANY KIND. YOU ASSUME THE
                RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO
                USE, THE SITE OR THE SERVICE. TO THE MAXIMUM EXTENT PERMITTED BY
                LAW, WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR
                IMPLIED, REGARDING THE SITE, INCLUDING, BUT NOT LIMITED TO, ANY
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, OR NONINFRINGEMENT. WE DO NOT WARRANT THAT THE SITE OR
                THE SERVICE WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF
                THE SITE OR THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.{" "}
                <br />
                <br />
                13. LIMITED LIABILITY. <br />
                <br /> OUR LIABILITY TO YOU IS LIMITED. TO THE MAXIMUM EXTENT
                PERMITTED BY LAW, IN NO EVENT SHALL WE BE LIABLE FOR DAMAGES OF
                ANY KIND (INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL, OR
                CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST DATA, REGARDLESS OF
                THE FORESEEABILITY OF THOSE DAMAGES) ARISING OUT OF OR IN
                CONNECTION WITH YOUR USE OF THE SITE OR ANY OTHER MATERIALS OR
                SERVICES PROVIDED TO YOU BY US. This limitation shall apply
                regardless of whether the damages arise out of breach of
                contract, tort, or any other legal theory or form of action.{" "}
                <br />
                <br />
                14. AFFILIATED SITES. <br />
                <br />
                We have no control over and no liability for any third party
                websites or materials. We work with a number of partners whose
                Internet sites may be linked with the Site. Because we have no
                control over the content and performance of these partner and
                affiliate sites, we make no guarantees about the accuracy,
                currency, content, or quality of the information provided by
                such sites, and we assume no responsibility for unintended,
                objectionable, inaccurate, misleading, or unlawful content that
                may reside on those sites. Similarly, from time to time in
                connection with your use of the Site, you may have access to
                content items (including, but not limited to, websites) that are
                owned by third parties. You acknowledge and agree that we make
                no guarantees about, and assumes no responsibility for, the
                accuracy, currency, content, or quality of this third party
                content, and that, unless expressly provided otherwise, these
                Terms of Use shall govern your use of any and all third party
                content. <br />
                <br />
                15. PROHIBITED USES. <br />
                <br />
                We impose certain restrictions on your permissible use of the
                Site. You are prohibited from violating or attempting to violate
                any security features of the Site, including, without
                limitation, (a) accessing content or data not intended for you,
                or logging onto a server or account that you are not authorized
                to access; (b) attempting to probe, scan, or test the
                vulnerability of the Site, or any associated system or network,
                or to breach security or authentication measures without proper
                authorization; (c) interfering or attempting to interfere with
                service to any user, host, or network, including, without
                limitation, by means of submitting a virus to the Site,
                overloading, ”flooding,” ”spamming,” ”mail bombing,” ”crashing”
                or instituting a ”DDOS” attack on the Site; (d) using the Site
                to send unsolicited e-mail, including, without limitation,
                promotions, or advertisements for products or services; (e)
                forging any TCP/IP packet header or any part of the header
                information in any e-mail or in any posting using the Site; or
                (f) attempting to modify, reverse-engineer, decompile,
                disassemble, or otherwise reduce or attempt to reduce to a
                human-perceivable form any of the source code used by us in
                providing the Site. Any violation of system or network security
                may subject you to civil and/or criminal liability. <br />
                <br />
                16. INDEMNITY. <br />
                <br />
                You agree to indemnify us for certain of your acts and
                omissions. You agree to indemnify, defend, and hold harmless
                Company, its affiliates, officers, directors, employees,
                consultants, agents, and representatives from any and all third
                party claims, losses, liability, damages, and/or costs
                (including reasonable attorney fees and costs) arising from your
                access to or use of the Site, your violation of these Terms of
                Use, or your infringement, or infringement by any other user of
                your account, of any intellectual property or other right of any
                person or entity. We will notify you promptly of any such claim,
                loss, liability, or demand, and will provide you with reasonable
                assistance, at your expense, in defending any such claim, loss,
                liability, damage, or cost. <br />
                <br />
                17. COPYRIGHT. <br />
                <br />
                All contents of Site or Service are: Copyright ©{" "}
                {new Date().getFullYear()} Haiti Streaming <br />
                <br />
                18. SEVERABILITY; WAIVER. <br />
                <br />
                If, for whatever reason, a court of competent jurisdiction finds
                any term or condition in these Terms of Use to be unenforceable,
                all other terms and conditions will remain unaffected and in
                full force and effect. No waiver of any breach of any provision
                of these Terms of Use shall constitute a waiver of any prior,
                concurrent, or subsequent breach of the same or any other
                provisions hereof, and no waiver shall be effective unless made
                in writing and signed by an authorized representative of the
                waiving party. <br />
                <br />
                19. NO LICENSE. <br />
                <br />
                Nothing contained on the Site should be understood as granting
                you a license to use any of the trademarks, service marks, or
                logos owned by us or by any third party. <br />
                <br />
                20. UNITED STATES USE ONLY. <br />
                <br />
                The Site is controlled and operated by Company from its offices
                in the State of California. The domain of the website is
                registered in the United States and the Site is hosted in the
                United States. The intended audience for this site consists of
                individuals in the United States only. Company makes no
                representation that any of the materials or the services to
                which you have been given access are available or appropriate
                for use in other locations. Your use of or access to the Site
                should not be construed as Company's purposefully availing
                itself of the benefits or privilege of doing business in any
                state or jurisdiction other than California and the United
                States. <br />
                <br />
                21. AMENDMENTS. <br />
                <br />
                ompany reserves the right to amend these Terms. Should Company
                seek to make such an amendment, which we determine is material
                in our sole discretion, we shall: (a) Provide you notice by
                email of said change 15 days prior to the change going into
                force, and (b) Publish on the home page the fact an amendment
                will be made. Should a court of competent jurisdiction rule this
                Amendment provision invalid, then this Amendment clause shall be
                terminated as part of this agreement. All amendments to the
                Terms shall be forward looking.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
